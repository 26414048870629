:root {
  --primaryColor   : #3A0B0D;
  --secondaryColor : #6A6C03;
}
*,
html,
body {
  overflow-x: hidden;/*add This*/
}
#logo {
  width: 50px;
}
#header {
  background: white;
  color: var(--primaryColor);
  border-bottom: thin solid var(--primaryColor);
  height: 60px;
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
}
